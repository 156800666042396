<template>
    <div class="mproCtThree">
        <!-- 标题 -->
        <div class="mcTTitle wow animate__animated animate__slideInUp">
            <p class="mcTBig">威有全网推营销优势，推广速度快、效果好</p>
            <p class="mcTSmall">让200个关键词全部登上搜索引擎首页</p>
        </div>
        <!-- 图文列表 -->
        <div class="mcTList">
            <ul>
                <li class="mcTLi">
                    <div class="mcTImg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <p class="mcTText">全搜索引擎首页排名</p>
                    <p class="mcTTexts">
                        抢占威有、360搜索、搜狗、有道、必应等搜索引擎首页自然排名
                        （PC端+手机端），高客流量导入，SEO推广效果更突出。
                    </p>
                </li>
                <li class="mcTLi">
                    <div class="mcTImg2">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <p class="mcTText">不限点击直达官网</p>
                    <p class="mcTTexts">
                        关键词不限点击次数，大量搜索流量直达官网；PC+手机端搜索首页自然排名，365天持效稳定收录。
                    </p>
                </li>
                <li class="mcTLi">
                    <div class="mcTImg3">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <p class="mcTText">商贸、门户网站展示 海量曝光</p>
                    <p class="mcTTexts">产品信息迅速覆盖众多个高权重商贸网站，超高曝光率，吸引买家注意，快速提高知名度。</p>
                </li>
                <li class="mcTLi">
                    <div class="mcTImg4">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <p class="mcTText">一键提交、操作简单</p>
                    <p class="mcTTexts">
                        仅需提交企业信息、产品资料，以及推广关键词，其他后续工作全由威有包办，省时省心省力，后顾无忧。
                    </p>
                </li>
                <li class="mcTLi">
                    <div class="mcTImg5">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <p class="mcTText">智能跟踪，实时掌握推广效果</p>
                    <p class="mcTTexts">推广效果直观展示，智能化跟踪分析；排名报表日更新，实时查看，尽情掌握推广动态。</p>
                </li>
                <li class="mcTLi">
                    <div class="mcTImg6">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <p class="mcTText">性价比高，节省费用</p>
                    <p class="mcTTexts">平均单个关键词每年仅需50元，任意点击不扣费告别传统的高昂费用，超高性价比。</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "MproCtThree",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.mproCtThree {
    width: 100%;
    height: 750px;
    margin-top: 100px;
    // 标题
    .mcTTitle {
        width: 60%;
        height: 80px;
        margin: 0 auto;
        .mcTBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 40px;
            font-weight: 900;
        }
        .mcTSmall {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
        }
    }
    // 图文列表
    .mcTList {
        width: 1351px;
        height: 600px;
        margin: 0 auto;
        margin-top: 50px;
        ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            .mcTLi {
                width: 33%;
                height: 300px;
                text-align: center;
                border-bottom: 1px solid gray;
                background-color: #fff;
                transition: 0.3s;

                .mcTImg,
                .mcTImg2,
                .mcTImg3,
                .mcTImg4,
                .mcTImg5,
                .mcTImg6 {
                    width: 70px;
                    height: 74px;
                    margin: 0 auto;
                    overflow: hidden;
                    margin-top: 40px;
                    position: relative;
                    img {
                        position: absolute;
                        left: -700px;
                    }
                }
                .mcTImg2 {
                    width: 60px;
                    img {
                        position: absolute;
                        left: -767px;
                    }
                }
                .mcTImg3 {
                    width: 85px;
                    img {
                        position: absolute;
                        left: -820px;
                    }
                }
                .mcTImg4 {
                    width: 68px;
                    img {
                        position: absolute;
                        left: -910px;
                    }
                }
                .mcTImg5 {
                    width: 80px;
                    img {
                        position: absolute;
                        left: -980px;
                    }
                }
                .mcTImg6 {
                    width: 73px;
                    img {
                        position: absolute;
                        left: -1070px;
                    }
                }
                .mcTText {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 20px;
                    font-weight: 900;
                }
                .mcTTexts {
                    width: 80%;
                    height: 200px;
                    font-size: 17px;
                    line-height: 30px;
                    margin: 0 auto;
                }
            }
            .mcTLi:nth-child(1),
            .mcTLi:nth-child(2),
            .mcTLi:nth-child(4),
            .mcTLi:nth-child(5) {
                border-right: 1px solid gray;
            }
            .mcTLi:nth-child(4),
            .mcTLi:nth-child(5),
            .mcTLi:nth-child(6) {
                border-bottom: none;
            }
            .mcTLi:hover {
                transform: scale(1.03);
                border: none;
                box-shadow: 1px 1px 10px 1px gray;
                border-radius: 10px;

                // img {
                //   transform: rotateY(180deg);
                //   transition: 0.5s;
                // }
            }
        }
    }
}
</style>
