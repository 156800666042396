<template>
    <!-- 搜索引擎 -->
    <div class="searchEg">
        <!-- 内容一 -->
        <MproCtOne></MproCtOne>
        <!-- 内容二 -->
        <MproCtTwo></MproCtTwo>
        <Pro2Com1></Pro2Com1>
        <Pro2Com2></Pro2Com2>
        <!-- 内容三 -->
        <MproCtThree></MproCtThree>
    </div>
</template>

<script>
import MproCtOne from "./SeContent/MproCtOne.vue";
import MproCtTwo from "./SeContent/MproCtTwo.vue";
import Pro2Com1 from "../../Homes/Product/ProContent2/Pro2Com1.vue";
import Pro2Com2 from "../../Homes/Product/ProContent2/Pro2Com2.vue";
import MproCtThree from "./SeContent/MproCtThree.vue";

export default {
    name: "SearchEg",
    components: {
        MproCtOne,
        MproCtTwo,
        Pro2Com1,
        Pro2Com2,
        MproCtThree,
    },
};
</script>

<style lang="less">
.searchEg {
    min-width: 1423px;
    margin: 0 auto;
}
</style>
