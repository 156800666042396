<template>
    <div class="pro2Com2">
        <img src="http://iv.okvu.cn/vugw/img/pc221.jpg" alt="" class="p2Bg" />
        <div class="p22Box">
            <!-- 标题 -->
            <div class="p22Title">
                <p class="p22Text wow animate__animated animate__fadeInUp">
                    威有除了做全网型的营销网站外，针对威有SEM推广客户，还提供顾<br />问咨询
                    及网站升级改造服务，使您的营销网站，更加适合威有SEM推<br />广创意着陆页、闪投、信息流 推广转化。
                </p>
            </div>
            <!-- 图片 -->
            <div class="p22Imgs">
                <img src="http://iv.okvu.cn/vugw/img/pc222.png" alt="" class="p22Img wow animate__animated animate__fadeIn" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pro2Com2",
};
</script>

<style lang="less">
.pro2Com2 {
    min-width: 1423px;
    height: 800px;
    margin-top: 50px;
    position: relative;
    .p2Bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
    }
    .p22Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .p22Title {
            width: 80%;
            height: 150px;
            line-height: 50px;
            text-align: center;
            margin: 0 auto;
            margin-top: 30px;
            .p22Text {
                font-size: 24px;
                font-weight: 900;
                color: white;
            }
        }
        // 图片
        .p22Imgs {
            width: 898px;
            height: 483px;
            margin-top: 30px;
            margin-left: 325px;
            text-align: center;
        }
    }
}
</style>
