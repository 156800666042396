<template>
    <div class="mproCtTwo">
        <img src="http://iv.okvu.cn/vugw/img/bg.jpg" alt="" class="mctBg" />
        <div class="mctBox">
            <!-- 标题 -->
            <div class="mctTitle wow animate__animated animate__slideInUp">
                <p class="mctBig">威有“SEO全网推”，低成本做有效的网站优化推广</p>
                <p class="mctSmall">
                    200个产品关键词迅速抢占各大搜索引擎首页，信息覆盖至国内近众多个高权重商贸平台，
                    同时关键词推广，流量直达官网，更快有效提升网站排名、浏览量，访客量、订单量。
                </p>
            </div>
            <!-- 地图 -->
            <div class="mctImgText">
                <img src="http://iv.okvu.cn/vugw/img/se21.png" alt="" class="mctImg wow animate__animated animate__slideInDown" />
                <p class="mctText">全网推</p>
                <div class="mctList">
                    <ul>
                        <li class="mctLi wow animate__animated animate__fadeIn" data-wow-delay="0.5s">订单</li>
                        <li class="mctLi wow animate__animated animate__fadeIn" data-wow-delay="0.6s">关键词</li>
                        <li class="mctLi wow animate__animated animate__fadeIn" data-wow-delay="0.7s">咨询</li>
                        <li class="mctLi wow animate__animated animate__fadeIn" data-wow-delay="0.8s">访客</li>
                        <li class="mctLi wow animate__animated animate__fadeIn" data-wow-delay="0.9s">浏览</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MproCtTwo",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.mproCtTwo {
    width: 100%;
    height: 800px;
    position: relative;
    .mctBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
    }
    .mctBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .mctTitle {
            width: 70%;
            height: 100px;
            margin: 0 auto;
            margin-top: 80px;
            .mctBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 30px;
                font-weight: 900;
            }
            .mctSmall {
                width: 100%;
                height: 50px;
                line-height: 25px;
                text-align: center;
            }
        }
        // 地图
        .mctImgText {
            width: 60%;
            height: 400px;
            margin-top: 80px;
            margin-left: 50px;
            position: relative;
            .mctImg {
            }
            .mctText {
                width: 100px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                text-align: center;
                font-weight: 900;
                position: absolute;
                top: 150px;
                left: 320px;
                color: orange;
            }
            .mctList {
                .mctLi {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    text-align: center;
                    padding-top: 20px;
                    box-sizing: border-box;
                    background-color: rgba(230, 212, 212, 0.2);
                    box-shadow: 1px 1px 5px 3px rgb(216, 210, 210);
                }
                .mctLi:nth-child(1) {
                    position: absolute;
                    top: 20%;
                    left: 20%;
                }
                .mctLi:nth-child(2) {
                    position: absolute;
                    bottom: 20%;
                    left: 26%;
                }
                .mctLi:nth-child(3) {
                    position: absolute;
                    top: 17%;
                    left: 45%;
                }
                .mctLi:nth-child(4) {
                    position: absolute;
                    top: 20%;
                    right: 25%;
                }
                .mctLi:nth-child(5) {
                    position: absolute;
                    bottom: 30%;
                    right: 30%;
                }
            }
        }
    }
}
</style>
