<template>
    <div class="mproCtOne">
        <div class="mcoBox" v-if="getAdvData(32).children">
            <a :href="getAdvData(32).children[0].url" target="_blank" rel="noopener noreferrer">
                <img v-if="getAdvData(32).children[0].image" :src="getAdvData(32).children[0].image" class="mbBg" />
                <!-- 标题 -->
                <div class="mcoTitle wow animate__animated animate__slideInUp">SEO推广能帮客户达到什么效果</div>
                <!-- 左图片 -->
                <div class="mcoLeft">
                    <img
                        :src="getAdvData(32).children[0].children.find(e => e.id == 307).image"
                        class="mcoLImg wow animate__animated animate__slideInLeft"
                    />
                </div>
                <!-- 右图片 -->
                <div class="mcoRight">
                    <img
                        :src="getAdvData(32).children[0].children.find(e => e.id == 308).image"
                        class="mcoRImg wow animate__animated animate__slideInRight"
                    />
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "MproCtOne",
    inject: ["getAdvData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.mproCtOne {
    width: 100%;
    height: 734px;
    position: relative;
    overflow: hidden;
    .mbBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        // transform: translateX(-50%);
        z-index: -1;
        object-fit: cover;
    }
    .mcoBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        & > a {
            display: block;
            width: 100%;
            height: 100%;
            padding-top: 50px;
        }
        // 标题
        .mcoTitle {
            width: 40%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 36px;
            font-weight: 900;
            margin: 0 auto;
            margin-top: 50px;
        }
        // 左图片
        .mcoLeft {
            width: 55%;
            height: 70%;
            margin-top: 12%;
            float: left;
            img {
                max-width: 100%;
            }
        }
        // 右图片
        .mcoRight {
            width: 40%;
            height: 90%;
            float: right;
            margin-right: 50px;
            margin-top: 20px;
            img {
                max-width: 100%;
            }
        }
    }
}
</style>
