<template>
    <div class="pro2Com1">
        <div class="p21Box">
            <!-- 标题 -->
            <div class="p21Title">营销型网站四大核心</div>
            <!-- 图文 -->
            <div class="p21ImgText">
                <!-- 左图 -->
                <div class="p21Left">
                    <img src="http://iv.okvu.cn/vugw/img/pc211.jpg" alt="" class="p21Img" v-show="showP21I === 1" />
                    <img src="http://iv.okvu.cn/vugw/img/pc212.jpg" alt="" class="p21Img" v-show="showP21I === 2" />
                    <img src="http://iv.okvu.cn/vugw/img/pc213.jpg" alt="" class="p21Img" v-show="showP21I === 3" />
                    <img src="http://iv.okvu.cn/vugw/img/pc214.jpg" alt="" class="p21Img" v-show="showP21I === 4" />
                </div>
                <!-- 右文 -->
                <div class="p21Right">
                    <ul>
                        <li class="p21Li" @mousemove="showP21(1)" :class="{ p21Active: isActive === 1 }">
                            <img src="http://iv.okvu.cn/vugw/img/seIcon1.png" alt="" class="p21Icon" />
                            <p class="p21Big">为了实现具体的销售目标</p>
                            <p class="p21Small" v-show="showP21T === 1">
                                产品和服务不求全面，但求爆款吸引兴趣；面向准客户的促销、优惠、下单；面向老客户为主的新品介绍、再销售。
                            </p>
                        </li>
                        <li class="p21Li" @mousemove="showP21(2)" :class="{ p21Active: isActive === 2 }">
                            <img src="http://iv.okvu.cn/vugw/img/seIcon2.png" alt="" class="p21Icon" />
                            <p class="p21Big">搜索引擎优化</p>
                            <p class="p21Small" v-show="showP21T === 2">
                                从营销型网站规划、设计制作、内容维护，从始至终充分考虑威有、360、谷歌等主流搜索引擎优化。
                            </p>
                        </li>
                        <li class="p21Li" @mousemove="showP21(3)" :class="{ p21Active: isActive === 3 }">
                            <img src="http://iv.okvu.cn/vugw/img/seIcon3.png" alt="" class="p21Icon" />
                            <p class="p21Big">流量监控与管理</p>
                            <p class="p21Small" v-show="showP21T === 3">
                                除威有统计、站长统计外，对影响咨询转化的页面还加个性化标记分析。
                            </p>
                        </li>
                        <li class="p21Li" @mousemove="showP21(4)" :class="{ p21Active: isActive === 4 }">
                            <img src="http://iv.okvu.cn/vugw/img/seIcon4.png" alt="" class="p21Icon" />
                            <p class="p21Big">接待工具的科学使用</p>
                            <p class="p21Small" v-show="showP21T === 4">
                                整合好电话、在线客服、QQ、企业QQ、营销QQ、微信公众号、企业微信、小程序、邮件等等几十种互动接待工具。
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pro2Com1",
    data() {
        return {
            // 左文展示下文
            showP21T: 1,
            // css样式
            isActive: 1,
            // 右文图片
            showP21I: 1,
        };
    },
    methods: {
        showP21(i) {
            this.isActive = i;
            this.showP21T = i;
            this.showP21I = i;
        },
    },
};
</script>

<style lang="less">
.pro2Com1 {
    min-width: 1423px;
    height: 700px;
    margin-top: 50px;
    .p21Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .p21Title {
            width: 30%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-weight: 900;
            font-size: 40px;
            margin: 0 auto;
        }
        // 图文
        .p21ImgText {
            width: 100%;
            height: 600px;
            margin-top: 50px;

            // 左图
            .p21Left {
                width: 45%;
                height: 80%;
                float: left;
                text-align: center;
                .p21Img {
                    width: 100%;
                    height: 100%;
                    padding-top: 50px;
                }
            }
            // 右文
            .p21Right {
                width: 50%;
                height: 100%;
                float: right;
                ul {
                    width: 100%;
                    height: 80%;
                    display: flex;
                    flex-direction: column;
                    margin-top: 10%;
                    .p21Li {
                        width: 90%;
                        cursor: pointer;
                        padding-left: 50px;
                        box-sizing: border-box;
                        transition: 0.3s;
                        border-radius: 20px;
                        .p21Icon {
                            margin-top: 20px;
                            float: left;
                        }
                        .p21Big {
                            width: 88%;
                            height: 80px;
                            line-height: 80px;
                            font-size: 30px;
                            font-weight: 900;
                            float: left;
                            margin-left: 15px;
                        }
                        .p21Small {
                            width: 90%;
                            height: 60px;
                            line-height: 30px;
                            float: left;
                        }
                    }
                    .p21Active {
                        background: linear-gradient(to right, #7781f1, #7781f1e0);
                        color: white;
                    }
                }
            }
        }
    }
}
</style>
